<template>
  <div class="yuqing-xiangqing commit">
    <div class="information-haeder-box">
      <div class="information-haeder">
        <div>
          <div class="quan" @click="$router.go(-1)">
            <img src="../../../assets/img/views/jiantou.png" alt />
          </div>
          <span class="xiangq">{{ $route.meta.title }}</span>
        </div>
      </div>
    </div>
    <!-- 面包屑 -->
    <el-breadcrumb separator-class="/" class="breadcrumb">
      <el-breadcrumb-item :to="{ path: '/sentiment/index' }">精准舆情</el-breadcrumb-item>
      <el-breadcrumb-item
        :to="{
          path: '/sentiment/index/yuqing',
          query: { labelId: labelId, id: accurateId, dataId: dataId },
        }"
      >/ 信息详情</el-breadcrumb-item>
      <el-breadcrumb-item>
        <span class="breadcrumb-meaasge">/</span> 评论跟评
      </el-breadcrumb-item>
    </el-breadcrumb>
    <!-- 主体部分 -->
    <div class="xiangqing-main">
      <div class="xiangqing-main-title">
        <span>评论/跟评（{{ count }}条）</span>
        <span class="xiangqing-download" @click="download">
          <img src="../../../assets/img/views/download.png" alt />
          导出评论/跟评
        </span>
      </div>
      <!-- 循环评论部分 -->
      <div v-loading="loading" style="min-height: 500px">
        <div v-if="textData.length != 0">
          <div class="xiangqing-main-count" v-for="(item, index) in textData" :key="index">
            <!-- 评论列表 -->
            <div style="display: flex; width: 100%; margin-bottom: 12px">
              <div class="xiangqing-main-left">
                <div style="margin-bottom: 16px">
                  <span class="main-count-fabu">{{ item.nickname }}</span>
                  <span class="main-count-time">{{ item.commentTime }}</span>
                  <span class="main-count-copy" @click="copy(item)">
                    <img src="../../../assets/img/views/fuzhi.png" alt />
                    <span style="margin-left: 4px">复制</span>
                  </span>
                </div>
                <p v-html="item.content" class="main-count-text"></p>
              </div>
            </div>
            <!-- 跟评列表 -->
            <div
              v-for="(item,index) in item.commentList"
              :key="index"
              style="display: flex; width: 100%; margin-top: 4px"
            >
              <div class="xiangqing-main-left xiangqing-main-left-two">
                <div style="margin-bottom: 16px">
                  <span class="main-count-fabu">{{ item.nickname }}</span>
                  <span class="main-count-time">{{ item.commentTime }}</span>
                  <span class="main-count-copy" @click="copy(item)">
                    <img src="../../../assets/img/views/fuzhi.png" alt />
                    <span style="margin-left: 4px">复制</span>
                  </span>
                </div>
                <p v-html="item.content" class="main-count-text"></p>
              </div>
            </div>
          </div>
        </div>
        <div v-else class="analyse-container">
          <div class="analyse-container-img">
            <img src="../../../assets/img/views/analyse-img.png" />
            <p>暂无数据</p>
          </div>
        </div>
      </div>

      <div class="block">
        <span class="total">
          共{{ count }}条, 当前显示第{{
          tablePage.page * tablePage.pageSize - tablePage.pageSize + 1
          }}-{{
          tablePage.page * tablePage.pageSize > count
          ? count
          : tablePage.page * tablePage.pageSize
          }}条
        </span>
        <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="tablePage.page"
          :page-sizes="tablePage.pageSizes"
          :page-size="tablePage.pageSize"
          layout="sizes, prev, pager, next,jumper"
          :total="count"
        ></el-pagination>
      </div>
    </div>
  </div>
</template>

<script>
import { commentList } from "../../../api/accurate/list";

export default {
  data() {
    return {
      count: "",
      releaseTime: "",
      loading: false,
      textData: [],
      //页数
      tablePage: {
        pageSizes: [10, 20, 30, 40, 50],
        page: 1,
        pageSize: 10,
        total: 0
      },
      accurateId: "",
      dataId: ""
    };
  },
  methods: {
    copy(item) {
      let msg = `昵称:${item.nickname}\n时间：${item.commentTime}\n评论内容:${item.content}`;
      this.$publicFun.copyFun(msg);
    },
    download() {
      let obj = {
        url: "api/msgquery/sentiment/export",
        method: "get",
        params: {},
        responseType: "blob"
      };
      obj.params = {
        accurateId: this.dataId,
        releaseTime: this.releaseTime
      };
      let textName = "评论.xlsx";
      this.$parent.downloadFun(obj, textName);
    },
    async commentList() {
      this.loading = true;
      let data = {
        accurateId: this.dataId,
        pageNum: this.tablePage.page,
        pageSize: this.tablePage.pageSize
      };
      const res = await commentList(data);
      if (res != undefined) {
        if (res.data.rows) {
          this.textData = res.data.rows;
        }
      }
      this.loading = false;
    },
    // 分页
    handleSizeChange(val) {
      this.tablePage.pageSize = val;
      this.tablePage.page = 1;
      this.commentList();
      window.scrollTo(0, 0);
    },
    handleCurrentChange(val) {
      this.tablePage.page = val;
      this.commentList();
      window.scrollTo(0, 0);
    },
    // 序号
    indexMethod(index) {
      return (
        index +
        this.tablePage.page * this.tablePage.pageSize -
        this.tablePage.pageSize +
        1
      );
    }
  },
  created() {
    let params = JSON.parse(decodeURIComponent(this.$route.query.info));
    if (params) {
      this.dataId = params.dataId;
      this.count = Number(params.count);
      this.releaseTime = params.releaseTime;
      this.accurateId = params.accurateId;
    }
    this.commentList();
  }
};
</script>

<style scoped>
.yuqing-xiangqing {
  padding-bottom: 24px;
}
.yuqing-xiangqing .xiangqing-main {
  min-height: 710px;
  padding: 32px 240px;
  margin: 0 24px;
  background-color: #fff;
  border-radius: 8px;
}
.yuqing-xiangqing .xiangqing-main-title {
  font-weight: bold;
  padding: 16px 24px;
  width: 100%;
  height: 57px;
  background: #f6f7fa;
  border: 1px solid #e4e6ec;
  display: flex;
  justify-content: space-between;
}
.yuqing-xiangqing .xiangqing-download {
  font-size: 14px;
  color: #2e59ec;
  cursor: pointer;
}
.yuqing-xiangqing .xiangqing-main-left {
  width: 100%;
}
.yuqing-xiangqing .xiangqing-main-left-two {
  background: #f7f7f7;
  padding: 24px;
}
.yuqing-xiangqing .xiangqing-main-count {
  padding: 9px 24px;
  background: #ffffff;
  border: 1px solid #e4e6ec;
  display: flex;
  flex-direction: column;
}
.yuqing-xiangqing .xiangqing-main-copy {
  width: 40px;
  height: 100%;
  background: #f6f7fa;
  border-radius: 4px;
  text-align: center;
  padding: 35px 13px;
  cursor: pointer;
}
.yuqing-xiangqing .main-count-fabu {
  margin-right: 16px;
  font-size: 16px;
  font-weight: bold;
  color: #333333;
}
.yuqing-xiangqing .main-count-time {
  font-size: 14px;
  color: #999999;
}
.yuqing-xiangqing .main-count-text {
  font-size: 14px;
  color: #333333;
  line-height: 20px;
}
.yuqing-xiangqing .breadcrumb .el-breadcrumb__item:nth-child(2) {
  margin-left: 9px;
}
.breadcrumb {
  margin-top: 77px;
  margin-left: 24px;
}

.analyse-container {
  width: 100%;
  height: 500px;
  background: #ffffff;
  border-radius: 4px;
  margin-top: 16px;
  text-align: center;
}

.analyse-container-img {
  position: relative;
  top: 50%;
  transform: translateY(-50%);
}

.analyse-container-img p {
  font-size: 14px;
  color: #999999;
}
.main-count-copy {
  float: right;
  display: flex;
  align-items: center;
  cursor: pointer;
}
</style>
